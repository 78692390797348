import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import { store } from './store';
import vuetify from './plugins/vuetify';

import Page from '@/components/page';
Vue.component('page', Page);

import Logo from '@/components/logo';
Vue.component('logo', Logo);

window.axios = require('axios');
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    store.dispatch('alert/error', error.response);
    return Promise.reject(error);
  }
);

axios.defaults.headers.common['Authorization'] = process.env.VUE_APP_API_KEY;

Vue.config.productionTip = false;

import VueMask from 'v-mask';
Vue.use(VueMask);

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput, {
  dropdownOptions: {
    showDialCodeInSelection: true,
  },
});

import loadingComponent from '@/components/loading';
Vue.component('loading-component', loadingComponent);

String.prototype.toUpper = function() {
  return this.toLowerCase().replace(/^\w|\s\w/g, function(letter) {
    return letter.toUpperCase();
  });
};

String.prototype.toUpperFirstLetter = function() {
  return this.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
};

import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  stripeAccount: 'acct_1GXWcRLdUrrOSabi',
  apiVersion: '2020-08-27',
  locale: 'auto',
};

Vue.use(StripePlugin, options);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

import firebase from 'firebase/app';
import 'firebase/analytics';

var devFirebaseConfig = {
  apiKey: 'AIzaSyC6PGfDkvmcuSpil7fxtEqjwMbM7LAwhQ0',
  authDomain: 'vpt-dev-6deec.firebaseapp.com',
  databaseURL: 'https://vpt-dev-6deec.firebaseio.com',
  projectId: 'vpt-dev-6deec',
  storageBucket: 'vpt-dev-6deec.appspot.com',
  messagingSenderId: '676629632747',
  appId: '1:676629632747:web:c39b5d014c928ec88d50ae',
  measurementId: 'G-NCLHCBW80D',
};
var prodFirebaseConfig = {
  apiKey: 'AIzaSyDIIEQ8G9HErUMV6Pc6BsCY7ZbA2kE2bM0',
  authDomain: 'withu-d0318.firebaseapp.com',
  databaseURL: 'https://withu-d0318.firebaseio.com',
  projectId: 'withu-d0318',
  storageBucket: 'withu-d0318.appspot.com',
  messagingSenderId: '455093896444',
  appId: '1:455093896444:web:e03f9319759ae09d5f8379',
  measurementId: 'G-BJVYN8FMZ3',
};
firebase.initializeApp(prodFirebaseConfig);
firebase.analytics();
