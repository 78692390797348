<template>
  <div
    class="d-flex align-center"
    style="position: absolute; left: 0px; top: 0px; padding: 10px; width: 100%"
    :class="showBarBackground && 'primary'"
  >
    <router-link to="/">
      <v-img
        alt="WithU Logo"
        class="shrink mr-2"
        contain
        src="@/assets/withu-logo-full.png"
        height="30"
        position="center left"
      />
    </router-link>
  </div>

  <!-- <div class="primary pa-3">
    <div :style="!mobile && 'padding-left:100px'">
      <v-img
        alt="WithU Logo"
        class="shrink mr-2"
        contain
        src="@/assets/withu-logo-full.png"
        transition="scale-transition"
        height="30"
        position="center left"
      />
    </div>
  </div> -->
</template>

<script>
import back from "@/components/back";

export default {
  components: {
    back,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    showBarBackground() {
      return this.$route.name == "Subscribe";
    },
  },
};
</script>
