<template>
  <v-container class="fill-height pa-0 primary1" style="color: #efefef" fluid>
    <v-row class="fill-height" no-gutters>
      <v-col v-if="isDesktop" class="fill-height pa-0" cols="12" md="6">
        <v-img
          class="fill-height"
          max-height="100vh"
          height="100vh"
          alt="WithU Active Woman"
          cover
          src="@/assets/home-left.jpg"
          position="center top"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="fill-height d-flex align-center justify-center"
        :style="isTablet ? 'padding: 60px 20px' : 'padding: 10px 20px'"
      >
        <loading-component v-if="initLoading" loading-component />
        <div v-else-if="hasLinkAndAdblock">
          <v-alert
            type="error"
            border="left"
            text
            dense
            class="ma-0 my-2"
            dismissible
            style="max-width: 500px"
          >
            Looks like you are trying to unlock a special offer but your Adblock
            is currently turned on. Please turn off your adblock and hit: Try
            again
          </v-alert>
          <div class="d-flex justify-center">
            <v-btn
              color="secondary"
              rounded
              dark
              class="black--text"
              @click="reload"
            >
              Try again
            </v-btn>
          </div>
        </div>
        <div
          v-else-if="existingUser"
          class="d-flex flex-column text-center"
          style="max-width: 450px"
        >
          <div class="text-h4 font-weight-bold">Ooops....</div>
          <div class="text-subtitle-1 mt-2">
            This email is already registered with a
            <strong>WithU</strong> account.
          </div>
          <div class="text-body-2">
            Please contact support for further help by using the chat below or
            emailing:
            <a
              href="mailto:support@withutraining.com"
              style="color: var(--v-secondary-base) !important"
              >support@withutraining.com</a
            >
          </div>
          <v-btn dark text class="mt-2" @click="resetUser">Try Again</v-btn>
        </div>
        <div v-else class="d-flex flex-column" style="max-width: 450px">
          <deal v-if="hasDeal"></deal>
          <div
            v-else
            class="font-weight-bold"
            :class="isMobile ? 'text-h6' : 'text-h6'"
          >
            {{ description }}
          </div>
          <div class="pt-2">
            <div
              v-for="(bullet, index) in bullets"
              :key="index"
              class="py-2 d-flex align-center"
            >
              <div
                style="
                  min-width: 50px;
                  min-height: 50px;
                  max-width: 50px;
                  max-hight: 50px;
                "
              >
                <v-img
                  :alt="bullet.icon"
                  class="shrink mr-2"
                  :src="getImgUrl(bullet.icon)"
                  height="40"
                  contain
                />
              </div>
              <div class="pl-5 lightGrey--text body-2">
                {{ bullet.text }}
              </div>
            </div>
          </div>

          <redeem-code
            v-if="canRedeemCode"
            :enteringCode="enteringCode"
            v-on:toggle="toggleEnteringCode"
          />
          <account v-show="!hideAccount" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import deal from "@/components/deal";
import account from "./account";
import redeemCode from "./code";

export default {
  name: "Home",
  components: {
    deal,
    account,
    redeemCode,
  },
  data() {
    return {
      error: false,

      hideAccount: false,
      enteringCode: false,
      bullets: [
        {
          icon: "tablet",
          text:
            "Access thousands of on-demand workouts, suitable for training in the gym, at home and on the move.",
        },
        {
          icon: "hiit",
          text:
            "Try HIIT, running, strength, yoga and more workouts with no equipment required.",
        },
        {
          icon: "advertising",
          text: "Enjoy new weekly content drops.",
        },
      ],
    };
  },
  watch: {
    canRedeemCode(val) {
      if (!val) {
        this.enteringCode = false;
        this.hideAccount = false;
      }
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      alert: (state) => state.alert,
    }),
    ...mapGetters({
      hasDeal: "account/hasDeal",
      freeTrial: "account/freeTrial",
      existingUser: "account/existingUser",
      canRedeemCode: "account/canRedeemCode",
    }),
    initLoading() {
      return this.account.initLoading;
    },
    loading() {
      return this.account.loading;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isTablet() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    hasLinkAndAdblock() {
      return this.account.redeem.adblock;
    },
    description() {
      return this.enteringCode
        ? "Enter your code to access WithU"
        : this.freeTrial
        ? `${this.freeTrial} free access to WithU`
        : "Get started";
    },
  },
  methods: {
    ...mapActions({
      clearExistingUser: "account/clearExistingUser",
    }),
    getImgUrl(pic) {
      return require("@/assets/" + pic + ".png");
    },
    reload() {
      window.location.reload();
    },
    toggleEnteringCode(val) {
      this.enteringCode = val;
      if (this.hideAccount) {
        this.hideAccount = false;
      }
    },
    resetUser() {
      this.clearExistingUser();
    },
  },
  beforeMount() {
    this.enteringCode = this.$route.path == "/redeem";
    if (this.canRedeemCode) {
      this.hideAccount = this.enteringCode;
    }
  },
};
</script>

<style scoped>
</style>

