<template>
  <div class="mb-4 mt-2">
    <div
      v-if="enteringCode"
      class="font-weight-bold"
      :class="isMobile ? 'text-h6' : 'text-h6'"
    >
      Redeem code
    </div>
    <div v-if="!enteringCode" class="d-flex align-center">
      <v-btn
        outlined
        color="secondary"
        dark
        class="black--text font-weight-bold px-10 text-subtitle-2 text-none"
        style="height: 30px"
        :style="hasDeal && 'opacity:0.4'"
        small
        @click="toggleEnteringCode"
        ><span>
          {{ hasDeal ? "Code redeemed" : "Redeem code" }}
        </span>
      </v-btn>
      <v-icon v-if="hasDeal" color="secondary" class="ml-3">
        mdi-checkbox-marked-circle-outline
      </v-icon>
    </div>
    <div
      v-else
      class="d-flex align-center mt-1"
      :class="isMobile ? 'flex-column' : 'flex-row'"
    >
      <input
        v-model="redeemCode"
        class="cardElement ma-0 font-weight-bold white--text custom-placeholer-color"
        placeholder="Enter code here"
        autocomplete="off"
        v-on:keyup="checkCode"
      />
      <v-btn
        color="secondary"
        dark
        class="black--text font-weight-bold text-subtitle-1"
        style="height: 40px"
        :class="isMobile ? 'mt-3' : 'ml-2'"
        :block="isMobile"
        @click="redeem"
        :disabled="!hasEnteredCode || loading"
        :loading="loading"
      >
        <span> REDEEM </span>
      </v-btn>
      <v-btn
        dark
        x-small
        text
        class="ml-2"
        :class="isMobile && 'mt-2'"
        @click="toggleEnteringCode"
      >
        Cancel</v-btn
      >
    </div>
    <v-alert
      class="font-weight-medium text-caption mt-2"
      color="red"
      border="left"
      text
      dense
      v-if="error && enteringCode"
    >
      Oops, we could not verify your code. Please check and try again or please
      reach out to our support by tapping on the chat icon</v-alert
    >
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import deal from "@/components/deal";

export default {
  name: "Redeem",
  components: {
    deal,
  },
  props: {
    enteringCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      redeemCode: "",
      error: false,
      codeDialog: false,
      loading: false,
    };
  },
  watch: {
    redeemCode(val) {
      if (val == null) {
        this.redeemCode = "";
        return;
      }
      this.redeemCode = this.redeemCode.toUpperCase();
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      alert: (state) => state.alert,
    }),
    ...mapGetters({
      hasDeal: "account/hasDeal",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    hasEnteredCode() {
      return this.redeemCode != null && this.redeemCode != "";
    },
    hasLinkAndAdblock() {
      return this.account.redeem.adblock;
    },
  },
  methods: {
    ...mapActions({
      verify: "account/verifyCode",
      setLoading: "account/setLoading",
    }),
    reload() {
      window.location.reload();
    },
    checkCode(event) {
      var code = this.redeemCode.split("-").join("");
      if (code.length > 0) {
        code = code.match(new RegExp(".{1,3}", "g")).join("-");
      }
      this.redeemCode = code;
    },
    async redeem() {
      this.loading = true;
      this.error = false;
      var result = await this.verify(this.redeemCode);
      if (result && result.deal) {
        this.error = false;
        this.codeDialog = false;
        this.toggleEnteringCode();
      } else {
        this.error = true;
      }
      this.loading = false;
    },
    register() {
      this.$router.push("/register");
    },
    toggleEnteringCode() {
      this.$emit("toggle", !this.enteringCode);
    },
  },
};
</script>

<style>
.curved-container {
  width: 100%;
  height: 50%;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  bottom: 0px;
  left: -25%;
  position: absolute;
  z-index: 0;
}

/* .centered-input >>> * {
  text-align: center;
  color: white !important;
} */

.custom-placeholer-color input::placeholder {
  color: white !important;
  opacity: 0.6;
  text-align: center;
  font-size: 20px;
}

.custom-label-color .v-label {
  color: white;
  opacity: 1;
  text-align: center;
}

.custom-placeholer-color input,
.custom-label-color input {
  color: white !important;
  text-align: center;
}
</style>