<template>
  <v-app>
    <v-main class="primary1">
      <app-alerts />
      <router-view></router-view>
      <app-bar></app-bar>
    </v-main>
  </v-app>
</template>

<script>
import appBar from "@/views/app/app-bar";
import appAlerts from "@/views/app/app-alerts.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    appBar,
    appAlerts,
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    version() {
      return process.env.PACKAGE_VERSION;
    },
  },
  methods: {
    ...mapActions({
      setLoading: "account/setInitLoading",
      setBranchData: "account/setBranchData",
      setBranchTimeout: "account/setTimeout",
    }),
    initBranch() {
      (function (b, r, a, n, c, h, _, s, d, k) {
        if (!b[n] || !b[n]._q) {
          for (; s < _.length; ) c(h, _[s++]);
          d = r.createElement(a);
          d.async = 1;
          d.src = "https://cdn.branch.io/branch-latest.min.js";
          k = r.getElementsByTagName(a)[0];
          k.parentNode.insertBefore(d, k);
          b[n] = h;
        }
      })(
        window,
        document,
        "script",
        "branch",
        function (b, r) {
          b[r] = function () {
            b._q.push([r, arguments]);
          };
        },
        { _q: [], _v: 1 },
        "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(
          " "
        ),
        0
      );
      var options = {};
      let self = this;
      try {
        branch.init(
          process.env.VUE_APP_BRANCH_KEY,
          options,
          function (err, data) {
            self.setBranchData(data);
          }
        );
      } catch (err) {
        // console.log(("initSession error");
        // console.log((e);
      }
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    checkAdblock() {
      var originalUrl = window.location.href;
      const queryString = window.location.search;
      var branchMatchId = this.getParameterByName("_branch_match_id");
      let self = this;

      // Function called if AdBlock is not detected
      setTimeout(() => {
        // console.log(("timeout finished");
        self.setBranchTimeout(branchMatchId);
      }, 10000);
    },
    initIntercom() {
      var payload = {
        app_id: "eaqzfc2c",
        user_id: this.account.register.user_id,
        email: this.account.register.email,
      };
      console.log(this.version);
      if (this.version) {
        payload.version = this.version;
      }

      // // console.log((payload);
      window.intercomSettings = payload;
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/eaqzfc2c";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    },
  },

  mounted() {
    this.checkAdblock();
  },

  beforeMount() {
    this.setLoading(true);
    this.initBranch();
    this.initIntercom();
  },
};
</script>

<style>
.cardElement {
  margin-top: 8px;
  padding: 0px 20px;
  border-radius: 3px;
  border: solid 2px #706882;
  font-weight: 550;
  font-family: "Helvetica Neue";
  font-variant: normal;
  font-size: 15px;
  width: 100%;
  height: 40px;
  line-height: 38px;
  outline: none;
  cursor: text;
  color: white;
}

.cardElement::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #706882;
  opacity: 1; /* Firefox */
}

.cardElement:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #706882;
}

.cardElement::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #706882;
}

.divider {
  height: 2px;
  width: 150px;
  background: rgba(3, 251, 197, 0.8);
  margin: 5px 0px;
}
</style>



