<template>
  <div>
    <v-container pa-0>
      <v-row no-gutters class="align-center">
        <v-col cols="4" md="3">
          <logo :showDeal="true" />
        </v-col>
        <v-col cols="8" md="9">
          <div class="px-5 py-2">
            <div
              :class="isMobile ? 'text-h6' : 'text-h6'"
              class="font-weight-bold"
            >
              {{ "Congratulations" }}
            </div>
            <div class="divider"></div>
            <div class="lightGrey--text body-2">
              {{ this.deal.web ? this.deal.web.type : "Partnership Deal" }}
            </div>
            <div class="secondary--text body-2">
              See what you've unlocked below
            </div>
          </div>
        </v-col>
        <div
          class="font-weight-bold mt-3"
          :class="isMobile ? 'text-h6' : 'text-h6'"
          v-html="description"
        ></div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  props: {},
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      freeTrial: "account/freeTrial",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    deal() {
      return this.account.redeem.deal;
    },
    logo() {
      if (this.deal && this.deal.web && this.deal.web.logo) {
        return this.deal.web.logo;
      } else {
        return null;
      }
    },
    code() {
      return this.account.redeem.code;
    },

    description() {
      var str = "";
      if (this.freeTrial) {
        str = `${this.freeTrial} free access to WithU`;
      }
      var subscription = this.account.redeem.deal.subscription;
      if (subscription && subscription.discount_percent) {
        if (str) {
          str += "<br/>then ";
        }
        str += `${subscription.discount_percent}% off your subscription`;
      }
      return str;
    },
  },
  methods: {},
};
</script>


